import React, { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { OBJECT_NAME_RULE, EMAIL_RULE, MOBILE_NUMBER_RULE } from "../../../utils/validation-utils";
import IdNameModel from "../../../models/common/IdNameModel";
import { GuidEnum } from "../../../models/enums/GuidEnum";
import CompanyInterviewerRequestModel from "../../../models/company/company-interviewers/request/CompanyInterviewerRequestModel";

interface CompanyInterviewerProps {
    objInterviewer: CompanyInterviewerRequestModel;
    primarySKills: IdNameModel[];
    cities: IdNameModel[];
    experiences: IdNameModel[];
    onCloseInterviewer: () => void;
    onInterviewerSave: (objInterviewer: CompanyInterviewerRequestModel) => void;
}

const CompanyInterviewerCreateComponent = (props: CompanyInterviewerProps) => {
    const {
        register,
        setValue,
        control,
        handleSubmit,
        formState: { errors, isValid },
    } = useForm<CompanyInterviewerRequestModel>({
        mode: "onChange",
        defaultValues: props.objInterviewer
    });

    const onSubmit = (formData: CompanyInterviewerRequestModel) => {
        formData.userName = formData.emailId;
        props.onInterviewerSave(formData);
    };

    return (
        <div className="card card-white">
            <div className="card-body">
                <form className="contact quform" onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="quform-element form-group">
                                <div className="quform-input">
                                    <label className="form-label">Name: <span className="required">*</span> </label>
                                    <input type="text" className="form-control" {...register("name", OBJECT_NAME_RULE)} placeholder="Name" autoComplete="off" />
                                    <span className="error">
                                        {errors.name && errors.name.message}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="quform-element form-group">
                                <div className="quform-input">
                                    <label className="form-label">Email: <span className="required">*</span> </label>
                                    <input type="email" className="form-control" {...register("emailId", EMAIL_RULE)} placeholder="Email/User Name" autoComplete="off" disabled={props.objInterviewer.interviewerId !== GuidEnum.Empty} />
                                    <span className="error">
                                        {errors.emailId && errors.emailId.message}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="quform-element form-group">
                                <div className="quform-input">
                                    <label className="form-label">Mobile No: <span className="required">*</span> </label>
                                    <input type="tel" className="form-control" {...register("mobileNumber", MOBILE_NUMBER_RULE)} placeholder="Mobile Number" autoComplete="off" />
                                    <span className="error">
                                        {errors.mobileNumber && errors.mobileNumber.message}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="quform-element form-group">
                                <div className="quform-input">
                                    <label className="form-label">Primary SKill: <span className="required">*</span> </label>
                                    <select className="form-control form-select" {...register("primarySkillId")}>
                                        <option value="">Select Primary Skill</option>
                                        {props.primarySKills.map((type, index) => {
                                            return (
                                                <option key={index} value={type.id}> {type.name} </option>
                                            );
                                        })}
                                    </select>
                                    <span className="error">
                                        {errors.primarySkillId && errors.primarySkillId.message}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                        <div className="quform-element form-group">
                                <div className="quform-input">
                                    <label className="form-label">City: <span className="required">*</span> </label>
                                    <select className="form-control form-select" {...register("cityId")}>
                                        <option value="">Select City</option>
                                        {props.cities.map((type, index) => {
                                            return (
                                                <option key={index} value={type.id}> {type.name} </option>
                                            );
                                        })}
                                    </select>
                                    <span className="error">
                                        {errors.cityId && errors.cityId.message}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="quform-element form-group">
                                <div className="quform-input">
                                    <label className="form-label">Experience: <span className="required">*</span> </label>
                                    <select className="form-control form-select" {...register("experienceId")}>
                                        <option value="">Select Experience</option>
                                        {props.experiences.map((type, index) => {
                                            return (
                                                <option key={index} value={type.id}> {type.name} </option>
                                            );
                                        })}
                                    </select>
                                    <span className="error">
                                        {errors.experienceId && errors.experienceId.message}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6"></div>
                        <div className="col-md-6">
                            <button className="btn btn-success btn-form" type="submit"><span>Save</span></button>
                            <button className="btn btn-danger btn-form" type="button" onClick={() => props.onCloseInterviewer()}>Close</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};
export default CompanyInterviewerCreateComponent;