import React, { useState, useEffect } from "react";
import { useAppSelector } from "../../context-store";
import Layout from "../../components/layout/Layout";
import "../../assets/InnerCSS/plugins/bootstrap/css/bootstrap.min.css";
import "../../assets/InnerCSS/plugins/font-awesome/css/font-awesome.min.css";
import "../../assets/InnerCSS/plugins/icomoon/style.css";
import "../../assets/InnerCSS/plugins/uniform/css/default.css";
import "../../assets/InnerCSS/plugins/switchery/switchery.min.css";
import "../../assets/InnerCSS/plugins/nvd3/nv.d3.min.css";
import "../../assets/InnerCSS/css/styles.css";
import "../../assets/InnerCSS/css/custom.css";
import IdNameModel from "../../models/common/IdNameModel";
import alertUtils from "../../utils/toaster-utils";
import masterDataService from "../../services/master-data-service";
import CompanyInterviewerComponent from "../../components/company/company-interviewer/CompanyInterviewerComponent";

const CompanyInterviewer = () => {
    const [primarySkills, setPrimarySkills] = useState<IdNameModel[]>([]);
    const [refreshPrimarySkills, setRefreshPrimarySkills] = useState<number>(2);
    const [cities, setCities] = useState<IdNameModel[]>([]);
    const [refreshCities, setRefreshCities] = useState<number>(2);
    const [experiences, setExperiences] = useState<IdNameModel[]>([]);
    const [refreshExperiences, setRefreshExperiences] = useState<number>(2);

    useEffect(() => {
        fillPrimarySkills();
        fillCities();
        fillExperiences();
    }, []);

    const fillPrimarySkills = () => {
        setRefreshPrimarySkills(2);
        masterDataService.loadPrimarySkills().then((res) => {
            setPrimarySkills(res.items);
            setRefreshPrimarySkills(refreshPrimarySkills * 5);
        }).catch((error) => {
            setRefreshPrimarySkills(refreshPrimarySkills * 5);
            alertUtils.showError(error);
        });
    };

    const fillCities = () => {
        setRefreshCities(2);
        masterDataService.loadAllCities().then((res) => {
            setCities(res.items);
            setRefreshCities(refreshCities * 5);
        }).catch((error) => {
            setRefreshCities(refreshCities * 5);
            alertUtils.showError(error);
        });
    };

    const fillExperiences = () => {
        setRefreshExperiences(2);
        masterDataService.loadExperiences().then((res) => {
            setExperiences(res.items);
            setRefreshExperiences(refreshExperiences * 5);
        }).catch((error) => {
            setRefreshExperiences(refreshExperiences * 5);
            alertUtils.showError(error);
        });
    };

    return (
        <Layout>
            <div className="page-title">
                <h4 className="breadcrumb-header"> Company Panels </h4>
            </div>
            {refreshPrimarySkills > 2 && refreshCities > 2 && refreshExperiences > 2 &&
                <CompanyInterviewerComponent primarySkills={primarySkills}
                cities={cities} experiences={experiences} />
            }
        </Layout>
    );
};
export default CompanyInterviewer;