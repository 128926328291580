import React, { useState } from "react";
import { useAppSelector } from "../../../context-store";
import VendorSubUserRequestModel from "../../../models/company/vendorUser/request/VendorSubUserRequestModel";
import { PageModeEnum } from "../../../models/enums/PageModeEnum";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import TableContainer from "@mui/material/TableContainer";
import TableSortLabel from "@mui/material/TableSortLabel";
import TableHead from "@mui/material/TableHead";
import copyClickboardUtils from "../../../utils/copy-clipboard";

interface VendorSubUserGridProps {
    vendorSubUserList: VendorSubUserRequestModel[];
    totalSize: number;
    currentPage: number;
    pagesize: number;
    sortColumn: string;
    sortDirection: "asc" | "desc";
    pageSizesList: number[];
    vendorsubUserNameFilterValue: string;
    onVendorSubUserAction: (row: VendorSubUserRequestModel, actionType: string) => void;
    onJobPostingVendorAction: (vendorSubUserId: string) => void;
    onPageChangeAction: (newPage: number) => void;
    onPageSizeChangeAction: (newPageSize: number) => void;
    onSortAction: (newSortColumn: string, sortDir: string) => void;
    onFilterAction: (filterColumn: string, filterValue: string) => void;
};

const VendorSubUserGridComponent = (props: VendorSubUserGridProps) => {
    let loggedInUser = useAppSelector((state) => state.authState.loggedInUser);
    const [vendorSubUserFilter, setVendorSubUserFilter] = useState<string>(props.vendorsubUserNameFilterValue);

    const editVendor = (row: VendorSubUserRequestModel) => {
        if (loggedInUser !== null) {
            let vendorSubUserRequestModel: VendorSubUserRequestModel = {
                vendorSubUserId: row.vendorSubUserId,
                vendorId: row.vendorId,
                userId: row.userId,
                vendorName: row.vendorName,
                vendorEmailId: row.vendorEmailId,
                vendorUserName: row.vendorUserName,
                vendorPhoneNumber: row.vendorPhoneNumber
            }
            props.onVendorSubUserAction(vendorSubUserRequestModel, PageModeEnum.Edit);
        }
    };

    const copyClipboard = (message: string) => {
        copyClickboardUtils.copy(message);
    };

    const columnsVendorSubUsers = [
        { Key: "VendorName", text: "Vendor SubUser Name", sort: true, searchable: true },
        { Key: "VendorEmailId", text: "Email", sort: true, searchable: false },
        { Key: "VendorPhoneNumber", text: "Mobile No", sort: false, searchable: false },
        { Key: "Actions", text: "Actions", sort: false, searchable: false }
    ];

    const createSortHandler = (property: string) => (event: React.MouseEvent) => {
        props.onSortAction(property, props.sortDirection === "asc" ? "desc" : "asc")
    };

    const handleChangePage = (event: any, newPage: number) => {
        props.onPageChangeAction(newPage);
    };

    const handleChangeRowsPerPage = (event: any) => {
        props.onPageSizeChangeAction(parseInt(event.target.value, 10));
    };

    const filterOnChange = (key: string, value: string) => {
        setVendorSubUserFilter(value);
        if ((value && value.length > 2) || value === "") {
            props.onFilterAction(key, value);
        }
    };

    return (
        <div className="row">
            <div className="col-md-12">
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columnsVendorSubUsers.map((column: any) => (
                                    <TableCell key={column.key} className="table-header-cell">
                                        {column.sort &&
                                            <TableSortLabel
                                                active={props.sortColumn === column.key}
                                                direction={props.sortColumn === column.key ? props.sortDirection : "asc"}
                                                onClick={createSortHandler(column.key)}>
                                                {column.text}
                                            </TableSortLabel>
                                        }
                                        {column.searchable &&
                                            <input type="text" placeholder="search" className="mat-header-filter-input" value={vendorSubUserFilter}
                                                onClick={(e) => e.stopPropagation()} onChange={(e) => filterOnChange(column.key, e.target.value)}></input>
                                        }
                                        {!column.sort &&
                                            <span>{column.text}</span>
                                        }
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.vendorSubUserList.map((vendorSubUser: VendorSubUserRequestModel, index) => (
                                <TableRow>
                                    <TableCell>{vendorSubUser.vendorName}</TableCell>
                                    <TableCell>{vendorSubUser.vendorEmailId}</TableCell>
                                    <TableCell>
                                        <span>{vendorSubUser.vendorPhoneNumber}</span><a title="copy" onClick={() => { copyClipboard(vendorSubUser.vendorPhoneNumber!.toString()); }}><i className="fa fa-clipboard"></i></a>
                                    </TableCell>
                                    <TableCell>
                                        <a style={{ display: 'block' }} onClick={() => { props.onJobPostingVendorAction(vendorSubUser.vendorSubUserId); }} title="Map Requirements">
                                            <i className="fa fa-address-card grid-icon blue"></i>
                                        </a>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={props.pageSizesList}
                    component="div"
                    count={props.totalSize}
                    rowsPerPage={props.pagesize}
                    page={props.currentPage - 1}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>
        </div>
    );
};
export default VendorSubUserGridComponent;