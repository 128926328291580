import { useState, MouseEvent } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import CompanyInterviewerGridModel from "../../../models/company/company-interviewers/response/CompanyInterviewerGridModel";

interface CompanyInterviewerGridProps {
    interviewerList: CompanyInterviewerGridModel[];
}

const CompanyInterviewerGridComponent = (props: CompanyInterviewerGridProps) => {

    const columnsSubUsers = [
        { key: "Name", text: "Name", sort: false, searchable: true },
        { key: "EmailId", text: "Email", sort: false, searchable: false },
        { key: "MobileNumber", text: "Mobile No", sort: false, searchable: false },
        { key: "PrimarySkills", text: "Primary Skills", sort: false, searchable: false }
    ];

    return (
        <div className="row">
            <div className="col-md-12">
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columnsSubUsers.map((column: any) => (
                                    <TableCell key={column.key} className="table-header-cell">
                                        <span>{column.text}</span>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.interviewerList.map((interviewer: CompanyInterviewerGridModel, index) => (
                                <TableRow>
                                    <TableCell>{interviewer.name}</TableCell>
                                    <TableCell>{interviewer.emailId}</TableCell>
                                    <TableCell>{interviewer.mobileNumber}</TableCell>
                                    <TableCell>{interviewer.primarySkills}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    );
};
export default CompanyInterviewerGridComponent;