const AppConfig = {
    apiBaseUrl: "https://coreapi.iaminterviewed.com/api",
    environment: "Prod",
    //apiBaseUrl: "https://localhost:7254/api",    
    //environment: "Dev",
    ////appBaseUrl: "https://coreapi.iaminterviewed.com/",
    account: {
        userTokenHeader: "x-auth-token",
        apiTokenHeader: "Authorization",
        authenticationUrl: "/Account/token",
        logOutUrl: "/Account/Logout",
        register: "/Account/Register",
        registerCompany: "/Account/RegisterCompany",
        userDetails: "/Account/UserDetails",
        verifyuser: "/Account/VerifyUser",
        changePassword: "/Account/ChangePassword"
    },
    layout: {
        getRoutes: "/RoleMapping/GetRoutes"
    },
    masterDataLoad: {
        getPrimarySkills: "MasterData/GetPrimarySkills",
        getSecondarySkills: "MasterData/GetSecondarySkills",
        getSoftSkills: "MasterData/GetSoftSkills",
        getRoles: "MasterData/GetRoles",
        getCountries: "MasterData/GetCountries",
        getCities: "MasterData/GetCities",
        getAllCities: "MasterData/GetAllCities",
        getDesignation: "MasterData/GetDesignation",
        getDomains: "MasterData/GetDomains",
        getExperiences: "MasterData/GetExperiences",
        getInterviewTypes: "MasterData/GetInterviewTypes",
        getJobTypes: "MasterData/GetJobTypes",
        getNoticePeriod: "MasterData/GetNoticePeriod",
        getScreens: "MasterData/GetScreens",
        getStatus: "MasterData/GetStatus",
        getStatusByScreen: "MasterData/GetStatusByScreen",
        getTimeSlots: "MasterData/GetTimeSlots",
        getZoomAccounts: "MasterData/GetZoomAccounts",
        getInterviewRounds: "MasterData/GetInterviewRounds",
        loadCompanies: "MasterData/loadCompanies",
        getJobCodes: "MasterData/getJobCodes"
    },
    envVariables: {
        //recapchaSiteKey: "6LdPCRElAAAAAIwzBtw1wdlnyMTeo9kznlOxsnaN",
        //recapchaSecretKey: ""
        //Server Keys for prod build
        recapchaSiteKey: "6LfZfRYlAAAAAL79aHhemQ1IvuxDsJ5U-4OIElpO",
        recapchaSecretKey: "6LfZfRYlAAAAAGREUoJICnB5M20hBZ-cjP4OgJ-A"
    },
    candidate: {
        getCandidateDesignations: "Candidate/CandidateDesignation",
        getCandidateProfile: "Candidate/CandidateProfile",
        saveCandidateProfile: "Candidate/SaveCandidateProfile",
        candidateScheduleInterview: "Candidate/ScheduleInterview",
        getCandidateDashboardDetails: "Candidate/CandidateDashboardDetails",
        getInterviewRatingDetails: "Candidate/InterviewRatingDetails",
        getCandidateAccessToViewProfileGrid: "Candidate/getCandidateAccessToViewProfileGrid",
        updateCandidateAccessToViewProfile: "Candidate/updateCandidateAccessToViewProfile",
        getCandidateRelatedJobRequirement: "Candidate/getCandidateRelatedJobRequirement",
        getCandidateAppliedJobRequirements: "Candidate/getCandidateAppliedJobRequirements",
        saveCandidateJobApplication: "Candidate/saveCandidateJobApplication",
        getCandidateHistory: "CandidateStatus/getCandidateHistory"
    },
    interviewer: {
        getInterviewerPersonalInfo: "Interviewer/InterviewerPersonalInfo",
        saveInterviewerPersonalInfo: "Interviewer/SaveInterviewerPersonalInfo",
        getInterviewerProfile: "Interviewer/InterviewerProfile",
        saveInterviewerProfile: "Interviewer/SaveInterviewerProfile",
        getInterviewerSchedules: "Interviewer/InterviewerSchedules",
        saveInterviewerSchedule: "Interviewer/SaveInterviewerSchedule",
        getInterviewDetails: "Interviewer/InterviewDetails",
        confirmInterview: "Interviewer/ConfirmInterview",
        pickUpSchedules: "Interviewer/PickUpSchedules",
        updateInterviewerForSchedule: "Interviewer/UpdateInterviewerForSchedule",
        getInterviewRatingDetails: "Interviewer/InterviewRatingDetails",
        updateInterviewRating: "Interviewer/UpdateInterviewRating",
        loadInterviewerDashboard: "Interviewer/InterviewerDashboard"
    },
    company: {
        dashboard: {
            getJobPostingDashboard: "CompanyDashboard/getJobPostingsDashboard"
        },
        department: {
            fillDepartments: "Department/fillDepartments",
            getDepartments: "Department/getDepartments",
            getDepartmentById: "Department/getDepartmentById",
            saveDepartment: "Department/saveDepartment"
        },
        designation: {
            fillDesignations: "Designation/fillDesignations",
            getDesignations: "Designation/getDesignations",
            getDesignationById: "Designation/getDesignationById",
            saveDesignation: "Designation/saveDesignation"
        },
        subUser: {
            fillSubUsers: "SubUser/fillSubUsers",
            getSubUsers: "SubUser/getSubUsers",
            getSubUserById: "SubUser/getSubUserById",
            saveSubUser: "SubUser/saveSubUser"
        },
        vendor: {
            fillVendors: "Vendor/fillVendors",
            getVendors: "Vendor/getVendors",
            getVendorById: "Vendor/getVendorById",
            saveVendor: "Vendor/saveVendor"
        },
        vendorSubUser: {
            fillVendorSubUsers: "VendorSubUser/fillVendorSubUsers",
            getVendorSubUsers: "VendorSubUser/getVendorSubUsers",
            getVendorSubUserById: "VendorSubUser/getVendorSubUserById",
            saveVendorSubUser: "VendorSubUser/saveVendorSubUser",
            getRequirementsForVendor: "VendorSubUser/getRequirementsForVendor",
            saveJobPostingVendorSubUser: "VendorSubUser/saveJobPostingVendorSubUser"
        },
        jobPosting: {
            fillJobCodes: "JobPosting/fillJobCodes",
            getAllJobPostings: "JobPosting/getAllJobPostings",
            getJobPostings: "JobPosting/getJobPostings",
            getJobPostingById: "JobPosting/getJobPostingById",
            saveJobPosting: "JobPosting/saveJobPosting",
            getInterviewProcesses: "JobPosting/getInterviewProcesses",
            getInterviewProcessSkills: "JobPosting/getInterviewProcessSkills",
            saveInterviewProcess: "JobPosting/saveInterviewProcess",
            getCompanyConfiguration: "JobPosting/getCompanyConfiguration",
            updateJobPostingStatus: "JobPosting/updateJobPostingStatus",
            getRequirementVendors: "JobPosting/getRequirementVendors",
            saveJobPostingVendor: "JobPosting/saveJobPostingVendor"
        },
        addProfiles: {
            fillVendorsForAddProfiles: "AddProfiles/fillVendorsForAddProfiles",
            addRequirementProfile: "AddProfiles/addRequirementProfile",
            getAddProfilesGrid: "AddProfiles/getAddProfilesGrid",
            updateProfileStatus: "AddProfiles/updateProfileStatus",
            getRequirementInterviewRounds: "AddProfiles/getRequirementInterviewRounds",
            scheduleInterview: "AddProfiles/scheduleInterview",
            interviewRatingDetails: "AddProfiles/interviewRatingDetails",
            getRequirementSummary: "AddProfiles/getRequirementSummary"
        },
        relatedShortlistedProfiles: {
            getRelatedProfilesGrid: "RelatedAndSelectedProfiles/getRelatedProfilesGrid",
            saveRelatedProfile: "RelatedAndSelectedProfiles/saveRelatedProfile",
            saveCandidateAccessToViewProfile: "RelatedAndSelectedProfiles/saveCandidateAccessToViewProfile",
            getShortlistedProfilesGrid: "RelatedAndSelectedProfiles/getShortlistedProfilesGrid",
            getAppliedProfilesGrid: "RelatedAndSelectedProfiles/getAppliedProfilesGrid",
            saveAppliedProfile: "RelatedAndSelectedProfiles/saveAppliedProfile",
            updateStatus: "RelatedAndSelectedProfiles/updateStatus"
        },
        clientEscalations: {
            getEscalations: "ClientEscalation/getEscalations",
            getEscalationsForRequirement: "ClientEscalation/getEscalationsForRequirement",
            saveEscalation: "ClientEscalation/saveEscalation"
        },
        companyInterviewers: {
            getAllCompanyPanels: "CompanyInterviewer/getAllCompanyPanels",
            saveCompanyInterviewer: "CompanyInterviewer/saveCompanyInterviewer"
        },
        getAllProfiles: "AllProfiles/getAllProfiles",
        getZoomRecordings: "AllProfiles/getZoomRecordings"
    },
    admin: {
        skills: {
            getPrimarySkills: "AdminSkill/getPrimarySkills",
            getPrimarySkillById: "AdminSkill/getPrimarySkillById",
            savePrimarySkill: "AdminSkill/savePrimarySkill",
            getSecondarySkills: "AdminSkill/getSecondarySkills",
            getSecondarySkillById: "AdminSkill/getSecondarySkillById",
            saveSecondarySkill: "AdminSkill/saveSecondarySkill",
            getTopics: "AdminSkill/getTopics",
            saveTopic: "AdminSkill/saveTopic",
            deleteTopic: "AdminSkill/deleteTopic"
        },
        dashboard: {
            getAdminUsers: "AdminDashboard/getAdminUsers",
            getAdminUserById: "AdminDashboard/getAdminUserById",
            saveAdminUser: "AdminDashboard/saveAdminUser",
            deleteAdminUser: "AdminDashboard/deleteAdminUser",
            interviewRatingDetails: "AdminDashboard/interviewRatingDetails",
            getAdminCompanyMapping: "AdminDashboard/getAdminCompanyMapping",
            updateAdminCompanyMapping: "AdminDashboard/updateAdminCompanyMapping"
        },
        candidate: {
            getCandidateByUniqueId: "AdminCandidate/getCandidateByUniqueId",
            getCandidatePassword: "AdminCandidate/getCandidatePassword",
            byPassPaymentGateway: "AdminCandidate/byPassPaymentGateway",
            getRegisteredCandidates: "AdminCandidate/getRegisteredCandidates",
            verifyCandidate: "AdminCandidate/verifyCandidate",
            getCandidateHistory: "CandidateStatus/getCandidateHistory",
            saveCandidateStatus: "CandidateStatus/saveCandidateStatus",
            getFollowUps: "CandidateStatus/getFollowUps",
            getCandidateStatus: "CandidateStatus/getCandidateStatus"
        },
        interviews: {
            getInterviews: "AdminInterviews/getInterviews",
            getInterviewersByPrimarySkill: "AdminInterviews/getInterviewersByPrimarySkill",
            updateInterview: "AdminInterviews/updateInterview",
            getInterviewsForRatingEdit: "AdminInterviews/getInterviewsForRatingEdit",
            updateInterviewRating: "AdminInterviews/UpdateInterviewRating",
            getInterviewRatingDetails: "AdminInterviews/InterviewRatingDetails"
        },
        interviewer: {
            getRegisteredInterviewers: "AdminInterviewer/getRegisteredInterviewers",
            approveInterviewer: "AdminInterviewer/approveInterviewer"
        },
        company: {
            getRegisteredCompanies: "AdminCompany/getRegisteredCompanies",
            getCompanyConfigurationById: "AdminCompany/getCompanyConfigurationById",
            updateCompanyCofiguration: "AdminCompany/updateCompanyCofiguration",
            getCompanyProfilesDashboard: "AdminCompany/getCompanyProfilesDashboard",
            getCandidatesToBeReleased: "AdminCompany/getCandidatesToBeReleased",
            releaseCandidate: "AdminCompany/releaseCandidate",
            getCompaniesForIAIRecruiter: "AdminCompany/getCompaniesForIAIRecruiter",
            getRecruiterByCompany: "AdminCompany/getRecruiterByCompany",
            getJobCodesForRecruiter: "AdminCompany/getJobCodesForRecruiter"
        }
    },
    fileUpload: {
        uploadFile: "FileUpload/UploadFile",
        downloadFile: "FileUpload/DownloadFile"
    }
};
export default AppConfig;