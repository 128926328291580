import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import VendorRequirementGridModel from "../../../models/company/vendorUser/response/VendorRequirementGridModel";

interface VendorSubUserRequirementsGridProps {
    requirementsList: VendorRequirementGridModel[];
}

const VendorSubUserRequirementsGridComponent = (props: VendorSubUserRequirementsGridProps) => {
    const columnsVendors = [
        { key: "Jobcode", text: "Jobcode", sort: false, searchable: false },
        { key: "CompanyName", text: "Company Name", sort: false, searchable: false }
    ];

    return (
        <div className="row">
            <div className="col-md-12">
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columnsVendors.map((column: any) => (
                                    <TableCell key={column.key} className="table-header-cell">
                                        <span>{column.text}</span>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.requirementsList.map((row: VendorRequirementGridModel, index) => (
                                <TableRow>
                                    <TableCell>{row.jobCode}</TableCell>
                                    <TableCell>{row.companyName}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    );
};
export default VendorSubUserRequirementsGridComponent;