import React, { useState, useEffect } from "react";
import { useAppSelector } from "../../../context-store";
import modalUtils from "../../../utils/modal-utils";
import alertUtils from "../../../utils/toaster-utils";
import { GeneralPoupConfig, ModalSize } from "../../../models/modal-types";
import IdNameModel from "../../../models/common/IdNameModel";
import loaderUtils from "../../../utils/loader-utils";
import { GuidEnum } from "../../../models/enums/GuidEnum";
import CompanyInterviewerRequestModel from "../../../models/company/company-interviewers/request/CompanyInterviewerRequestModel";
import companyInterviewerService from "../../../services/company/company-interviewer-service";
import CompanyInterviewerGridComponent from "./CompanyInterviewerGridComponent";
import CompanyInterviewerCreateComponent from "./CompanyInterviewerCreateComponent";
import CompanyInterviewerGridModel from "../../../models/company/company-interviewers/response/CompanyInterviewerGridModel";

interface CompanyInterviewerProps {
    primarySkills: IdNameModel[];
    cities: IdNameModel[];
    experiences: IdNameModel[];
}

const CompanyInterviewerComponent = (props: CompanyInterviewerProps) => {
    let loggedInUser = useAppSelector((state) => state.authState.loggedInUser);
    const [interviewerList, setInterviewerList] = useState<CompanyInterviewerGridModel[]>([]);
    const [refreshInterviewers, setRefreshInterviewers] = useState<number>(2);

    useEffect(() => {
        getInterviewers();
    }, []);

    const getInterviewers = () => {
        if (loggedInUser?.profileId) {
            setRefreshInterviewers(2);
            modalUtils.showLoader(loaderUtils.getLoadDataText());
            companyInterviewerService.getAllCompanyPanels().then((res) => {
                modalUtils.closeModal();
                setInterviewerList([]);
                setInterviewerList(res.items);
                setRefreshInterviewers(refreshInterviewers * 5);
            }).catch((error) => {
                setRefreshInterviewers(refreshInterviewers * 5);
                modalUtils.closeModal();
                alertUtils.showError(error);
            });
        }
    };

    const addInterviewer = () => {
        if (loggedInUser !== null) {
            let objInterviewer: CompanyInterviewerRequestModel = {
                interviewerId: GuidEnum.Empty,
                companyId: GuidEnum.Empty,
                name: "",
                userName: "",
                emailId: "",
                mobileNumber: 0,
                primarySkillId: 0,
                secondarySkillIds: [],
                cityId: 0,
                experienceId: 0,
                userId: GuidEnum.Empty
            }
            const config: GeneralPoupConfig = {
                title: "Add SubUser",
                content: (
                    <CompanyInterviewerCreateComponent
                        objInterviewer={objInterviewer}
                        primarySKills={props.primarySkills}
                        cities={props.cities}
                        experiences={props.experiences}
                        onCloseInterviewer={onCloseInterviewerHandler}
                        onInterviewerSave={onInterviewerSaveHandler} />
                ),
                size: ModalSize.LG,
                className: "model-min-height-500"
            };
            modalUtils.showPopup(config);
        }
    };

    const onCloseInterviewerHandler = () => {
        modalUtils.closeModal();
    };

    const onInterviewerSaveHandler = (objInterviewer: CompanyInterviewerRequestModel) => {
        setRefreshInterviewers(2);
        modalUtils.showLoader(loaderUtils.getSaveDataText());
        companyInterviewerService.saveCompanyInterviewer(objInterviewer).then((res) => {
            modalUtils.closeModal();
            if (res.data === false) {
                alertUtils.showError(res.errorMessages.toString());
            } else {
                alertUtils.showSuccess(res.informationMessages.toString());
                getInterviewers();
            }
        }).catch((error) => {
            modalUtils.closeModal();
            alertUtils.showError(error);
        });
    };

    return (
        <div className="card card-white">
            <div className="card-head card-head-text-right">
                <a className="btn-grid-add-action" title="Reload Data" onClick={() => getInterviewers()}>
                    <i className="fa fa-refresh"></i>
                </a>
                <a className="btn-grid-add-action" onClick={addInterviewer}> + Add Panel</a>
            </div>
            <div className="card-body">
                {refreshInterviewers > 2 &&
                    <CompanyInterviewerGridComponent
                        key={refreshInterviewers}
                        interviewerList={interviewerList} />
                }
            </div>
        </div>
    );
};
export default CompanyInterviewerComponent;