import React, { useState, useEffect } from "react";
import { useAppSelector } from "../../../context-store";
import { Redirect, useHistory } from "react-router";
import dateUtils from "../../../utils/date-utils";
import DataGrid from "../../common/table/DataGrid";
import InterviewerService from '../../../services/interviewer/interviewer-service';
import alertUtils from "../../../utils/toaster-utils";
import InterviewerDashboardRequestParameters from "../../../models/interviewer/request/InterviewerDashboardRequestParameters";
import { SortDirection } from "../../../models/enums/SortDirectionEnum";
import InterviewerDashboardModel from "../../../models/interviewer/response/InterviewerDashboardModel";
import PagedListModel from "../../../models/common/PagedListModel";
import DateTimePicker from "react-datepicker";
import { GeneralPoupConfig, ModalSize } from "../../../models/modal-types";
import modalUtils from "../../../utils/modal-utils";
import ConfirmInterviewPopUp from "./ConfirmInterviewComponent";
import { ConfirmEnum } from "../../../models/enums/ConfirmEnum";
import RatingDetailsPopUp from "../../candidate/dashboard/RatingDetailsComponent";
import { PagingEnum } from "../../../models/enums/IAIEnum";
import masterDataService from "../../../services/master-data-service";
import loaderUtils from "../../../utils/loader-utils";
import InterviewerDashboardGridComponent from "./InterviewerDashboardGridComponent";

const InterviewerDashboardComponent = () => {
    let loggedInUser = useAppSelector((state) => state.authState.loggedInUser);
    const [startDate, setStartDate] = useState(dateUtils.addDays(new Date(), -7) as any);
    const [endDate, setEndDate] = useState(new Date() as any);
    const [dashboardPagedList, setDashboardPagedList] = useState<PagedListModel<InterviewerDashboardModel>>();
    const [dashboardList, setDashboardList] = useState<InterviewerDashboardModel[]>([]);
    const [refreshDashboardList, setRefreshDashboardList] = useState<number>(2);
    const history = useHistory();
    const [candidateNameFilter, setCandidateNameFilter] = useState<string>("");
    const [page, setPage] = useState<number>(PagingEnum.page);
    const [pageSize, setPageSize] = useState<number>(PagingEnum.pageSize);
    const [sortColumn, setSortColumn] = useState<string>("CandidateName");
    const [sortDirection, setSortDirection] = useState<number>(PagingEnum.sortDirection);
    const [pageSizesList, setPageSizesList] = useState<number[]>([]);

    useEffect(() => {
        loadPageSizes();
        getInterviewerDashboard(getSearchFilters(candidateNameFilter, page, pageSize, sortColumn, sortDirection));
    }, []);

    const loadPageSizes = () => {
        masterDataService.loadPageSizesForPaging().then((res) => {
            setPageSizesList(res);
        }).catch((error) => {
            alertUtils.showError(error);
        });
    };

    const getSearchFilters = (candidateNameFilter: string, page: number, pageSize: number, sortColumn: string, sortDirection: number) => {
        var inputData: InterviewerDashboardRequestParameters = {
            page: page,
            pageSize: pageSize,
            sort: sortColumn,
            sortDir: sortDirection,
            interviewerId: "",
            startDate: dateUtils.getFormattedDateFromDate(startDate),
            endDate: dateUtils.getFormattedDateFromDate(endDate),
            candidateName: candidateNameFilter,
            interviewTypeId: 0,
            statusId: 0
        };
        return inputData;
    };

    const startDateHanler = (date: any) => {
        setStartDate(date);
    };

    const endDateHanler = (date: any) => {
        setEndDate(date);
    };

    const getInterviewerDashboard = (inputParms: InterviewerDashboardRequestParameters) => {
        if (loggedInUser) {
            setRefreshDashboardList(2);
            inputParms.interviewerId = loggedInUser?.profileId;
            modalUtils.showLoader(loaderUtils.getLoadDataText());
            InterviewerService.loadInterviewerDashboard(inputParms).then((res) => {
                modalUtils.closeModal();
                setDashboardList([]);
                setDashboardPagedList(res);
                setDashboardList(res.items);
                setRefreshDashboardList(refreshDashboardList * 5);
            }).catch((error) => {
                modalUtils.closeModal();
                alertUtils.showError("Erorr fetching Schedules");
            });
        }
    };

    const onConfirmInterviewActionHandler = (interview: InterviewerDashboardModel) => {
        const config: GeneralPoupConfig = {
            title: !interview.isConfirmedByInterviewer ? "Confirm Interview" : "Interview Details",
            content: (
                <ConfirmInterviewPopUp
                    interview={interview}
                    onConfirmInterview={confirmInterviewHandler}
                />
            ),
            size: ModalSize.XL,
            className: "model-min-height-500"
        };
        modalUtils.showPopup(config);
    };

    const confirmInterviewHandler = (interviewId: string) => {
        if (loggedInUser) {
            modalUtils.showLoader(loaderUtils.getSaveDataText());
            InterviewerService.ConfirmInterview(interviewId, loggedInUser.profileId).then((res) => {
                alertUtils.showSuccess(res.informationMessages.toString());
                modalUtils.closeModal();
                getInterviewerDashboard(getSearchFilters(candidateNameFilter, page, pageSize, sortColumn, sortDirection));
            }).catch((error) => {
                modalUtils.closeModal();
                alertUtils.showError("Erorr fetching Details" + error);
            });
        }
    };

    const onRateInterviewActionHandler = (interview: InterviewerDashboardModel) => {
        if (interview.isRated === ConfirmEnum.Yes) {
            InterviewerService.getInterviewRatingDetails(interview.interviewId).then((res) => {
                if (res.isSuccess) {
                    const config: GeneralPoupConfig = {
                        title: "Rating Details",
                        content: (
                            <RatingDetailsPopUp
                                objRatingDetails={res.data} />
                        ),
                        size: ModalSize.XL,
                        className: "model-rating-details-badge"
                    };
                    modalUtils.showPopup(config);
                } else {
                    alertUtils.showError(res.errorMessages.toString());
                }
            }).catch((error) => {
                alertUtils.showError("Erorr fetching Schedules");
            });
        } else {
            history.push("/rate-interview/" + interview.interviewId);
        }
    };

    const onPageChangeActionHandler = (newPage: number) => {
        setPage(newPage);
        getInterviewerDashboard(getSearchFilters(candidateNameFilter, newPage, pageSize, sortColumn, sortDirection));
    };

    const onPageSizeChangeActionHandler = (newPageSize: number) => {
        setPageSize(newPageSize);
        setPage(PagingEnum.page);
        getInterviewerDashboard(getSearchFilters(candidateNameFilter, PagingEnum.page, newPageSize, sortColumn, sortDirection));
    };

    const onSortActionHandler = (newSortColumn: string, sortDir: string) => {
        setSortColumn(newSortColumn);
        setSortDirection(sortDir === "asc" ? SortDirection.Asc : SortDirection.Desc);
        getInterviewerDashboard(getSearchFilters(candidateNameFilter, page, pageSize, newSortColumn, sortDir === "asc" ? SortDirection.Asc : SortDirection.Desc));
    };

    const onFilterActionHandler = (filterColumn: string, filterValue: string) => {
        setCandidateNameFilter(filterValue);
        setPage(0);
        getInterviewerDashboard(getSearchFilters(filterValue, 0, pageSize, sortColumn, sortDirection));
    };

    return (
        <div className="card card-white">
            <div className="card-body">
                <div className="row">
                    <div className="col-md-4">
                        <label className="form-label">Start Date: </label>
                        <DateTimePicker
                            selectsStart
                            onChange={startDateHanler}
                            selected={startDate}
                            timeInputLabel="Time:"
                            dateFormat="yyyy-MMM-dd"
                            className="form-control"
                            onKeyDown={(e) => {
                                e.preventDefault();
                            }}
                        />
                    </div>
                    <div className="col-md-4">
                        <label className="form-label">End Date: </label>
                        <DateTimePicker
                            selectsStart
                            onChange={endDateHanler}
                            selected={endDate}
                            timeInputLabel="Time:"
                            dateFormat="yyyy-MMM-dd"
                            className="form-control"
                            onKeyDown={(e) => {
                                e.preventDefault();
                            }}
                        />
                    </div>
                    <div className="col-md-4">
                        <label className="form-label"></label>
                        <button disabled={startDate === "" || endDate === ""} className="btn btn-success btn-form block-display" type="submit" onClick={() => getInterviewerDashboard(getSearchFilters(candidateNameFilter, page, pageSize, sortColumn, sortDirection))}><span>Search</span></button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        {refreshDashboardList > 2 &&
                            <InterviewerDashboardGridComponent
                                key={refreshDashboardList}
                                interviewerDashboardList={dashboardList}
                                totalSize={dashboardPagedList?.totalCount ? dashboardPagedList?.totalCount : dashboardList.length}
                                currentPage={page + 1}
                                pagesize={pageSize}
                                sortColumn={sortColumn}
                                sortDirection={sortDirection === SortDirection.Asc ? "asc" : "desc"}
                                pageSizesList={pageSizesList}
                                candidateNameFilterValue={candidateNameFilter}
                                onConfirmInterviewAction={onConfirmInterviewActionHandler}
                                onRateInterviewAction={onRateInterviewActionHandler}
                                onPageChangeAction={onPageChangeActionHandler}
                                onPageSizeChangeAction={onPageSizeChangeActionHandler}
                                onSortAction={onSortActionHandler}
                                onFilterAction={onFilterActionHandler} />
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};
export default InterviewerDashboardComponent;