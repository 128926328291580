import { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import AddProfilesSummaryGridModel from "../../../models/company/addProfiles/response/AddProfilesSummaryGridModel";
import modalUtils from "../../../utils/modal-utils";
import loaderUtils from "../../../utils/loader-utils";
import addProfilesService from "../../../services/company/add-profiles-service";
import alertUtils from "../../../utils/toaster-utils";
import dateTimeUtils from "../../../utils/date-utils";
import DateTimePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AddProfilesSummarySearchInputModel from "../../../models/company/addProfiles/request/AddProfilesSummarySearchInputModel";

interface RequirementSummaryProps {
    requirementId: string;
};

const SummaryGridDefault = {
    requirementId: "",
    noOfProfilesReceived: "",
    noOfInterviewsCompleted: "",
    noOfInterviewsScheduled: "",
    noOfCandidatesWithRatingAbove3: "",
    noOfCandidatesWithRatingUnder3: "",
    noOfNoResponses: ""
};

const RequirementSummaryGridComponent = (props: RequirementSummaryProps) => {
    const [summaryData, setSummaryData] = useState<AddProfilesSummaryGridModel>(SummaryGridDefault);
    const [refreshSummary, setRefreshSummary] = useState<number>(2);
    const [startDateSearch, setStartDateSearch] = useState(dateTimeUtils.addDays(new Date(), -7) as any);
    const [endDateSearch, setEndDateSearch] = useState(new Date() as any);

    useEffect(() => {
        getRequirementSummary();
    }, []);

    const startDateHanler = (date: any) => {
        setStartDateSearch(date);
    };

    const endDateHanler = (date: any) => {
        setEndDateSearch(date);
    };

    const getRequirementSummary = () => {
        setRefreshSummary(2);
        //modalUtils.showLoader(loaderUtils.getLoadDataText());
        let searchData: AddProfilesSummarySearchInputModel = {
            requirementId: props.requirementId,
            startDate: startDateSearch === "" ? null : startDateSearch,
            endDate: endDateSearch === "" ? null : endDateSearch
        };
        addProfilesService.getRequirementSummary(searchData).then((res) => {
            //modalUtils.closeModal();
            setSummaryData(res.data);
            setRefreshSummary(refreshSummary * 5);
        }).catch((error) => {
            //modalUtils.closeModal();
            alertUtils.showError(error);
        });
    };

    const columnsSummary = [
        { key: "NoOfProfilesReceived", text: "No of Profiles Recieved", sort: false, searchable: false },
        { key: "NoOfInterviewsCompleted", text: "No of Interviews Completed", sort: false, searchable: false },
        { key: "NoOfInterviewsScheduled", text: "No of Interviews Scheduled", sort: false, searchable: false },
        { key: "NoOfCandidatesWithRatingAbove3", text: "No of Candidates with 3 and Above Rating", sort: false, searchable: false },
        { key: "NoOfCandidatesWithRatingUnder3", text: "No of Candidates with 2.5 to 2.9 Rating", sort: false, searchable: false },
        { key: "NoOfNoResponses", text: "No of No Responses", sort: false, searchable: false },
    ];

    return (
        <div>
            <div className="row">
                <div className="col-md-3">
                    <label>Start Date</label>
                    <DateTimePicker
                        selectsStart
                        onChange={startDateHanler}
                        selected={startDateSearch}
                        timeInputLabel="Time:"
                        dateFormat="yyyy-MMM-dd"
                        className="form-control"
                        onKeyDown={(e) => {
                            e.preventDefault();
                        }}
                    />
                </div>
                <div className="col-md-3">
                    <label>End Date</label>
                    <DateTimePicker
                        selectsStart
                        onChange={endDateHanler}
                        selected={endDateSearch}
                        timeInputLabel="Time:"
                        dateFormat="yyyy-MMM-dd"
                        className="form-control"
                        onKeyDown={(e) => {
                            e.preventDefault();
                        }}
                    />
                </div>
                <div className="col-md-3">
                    <button className="btn btn-success" type="button" onClick={() => getRequirementSummary()}>
                        <i className="icon-search">Search</i>
                    </button>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {columnsSummary.map((column: any) => (
                                        <TableCell key={column.key} className="table-header-cell">
                                            <span>{column.text}</span>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>{summaryData.noOfProfilesReceived}</TableCell>
                                    <TableCell>{summaryData.noOfInterviewsCompleted}</TableCell>
                                    <TableCell>{summaryData.noOfInterviewsScheduled}</TableCell>
                                    <TableCell>{summaryData.noOfCandidatesWithRatingAbove3}</TableCell>
                                    <TableCell>{summaryData.noOfCandidatesWithRatingUnder3}</TableCell>
                                    <TableCell>{summaryData.noOfNoResponses}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
        </div>
    );
};
export default RequirementSummaryGridComponent;