import React from "react";
import IdNameModel from "../../../models/common/IdNameModel";
import VendorSubUserRequirementsGridComponent from "./VendorSubUserRequirementsGridComponent";
import { useForm } from "react-hook-form";
import JobPostingVendorRequestModel from "../../../models/company/jobPosting/request/JobPostingVendorRequestModel";
import { GuidEnum } from "../../../models/enums/GuidEnum";
import VendorRequirementGridModel from "../../../models/company/vendorUser/response/VendorRequirementGridModel";

interface JobPostingVendorProps {
    requirementVendors: VendorRequirementGridModel[];
    vendorForRequiremrntList: IdNameModel[];
    vendorSubUserId: string;
    onVendorSave: (objVendor: JobPostingVendorRequestModel) => void;
    onCloseVendor: () => void;
};

const VendorSubUserRequirementsComponent = (props: JobPostingVendorProps) => {
    const {
        register,
        setValue,
        getValues,
        handleSubmit,
        formState: { errors, isValid },
    } = useForm<JobPostingVendorRequestModel>({
        mode: "onChange"
    });

    const onSubmit = (formData: JobPostingVendorRequestModel) => {
        formData.vendorId = props.vendorSubUserId;
        formData.userId = GuidEnum.Empty;
        props.onVendorSave(formData);
    }

    return (
        <div className="card card-white">
            <div className="card-body">
                <form className="contact quform" onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="quform-element form-group">
                                <div className="quform-input">
                                    <label className="form-label">Requirement: <span className="required">*</span> </label>
                                    <select className="form-control form-select" {...register("requirementId", { required: true })}>
                                        <option value="0">Select Jobcode</option>
                                        {props.vendorForRequiremrntList.map((type, index) => {
                                            return (
                                                <option key={index} value={type.guId}> {type.name} </option>
                                            );
                                        })}
                                    </select>
                                    <span className="error">
                                        {errors.requirementId && errors.requirementId.message}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <button className="btn btn-success btn-form" type="submit"><span>Save</span></button>
                            <button className="btn btn-danger btn-form" type="button" onClick={() => props.onCloseVendor()}>Close</button>
                        </div>
                    </div>
                </form>
                <VendorSubUserRequirementsGridComponent
                    requirementsList={props.requirementVendors} />
            </div>
        </div>
    );
};
export default VendorSubUserRequirementsComponent;