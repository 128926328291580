import AppConfig from "../../config";
import httpClient from "../../utils/http-client";
import BaseResponse from "../../models/common/BaseResponse";
import BaseResponseList from "../../models/common/BaseResponseList";
import CompanyInterviewerRequestModel from "../../models/company/company-interviewers/request/CompanyInterviewerRequestModel";
import CompanyInterviewerGridModel from "../../models/company/company-interviewers/response/CompanyInterviewerGridModel";

class CompanyInterviewerService {
    async getAllCompanyPanels() {
        let res: BaseResponseList<CompanyInterviewerGridModel[]> = await httpClient.get<BaseResponseList<CompanyInterviewerGridModel[]>>(AppConfig.company.companyInterviewers.getAllCompanyPanels);
        return res;
    };

    async saveCompanyInterviewer(inputParms: CompanyInterviewerRequestModel) {
        let res: BaseResponse<boolean> = await httpClient.post<BaseResponse<boolean>>(AppConfig.company.companyInterviewers.saveCompanyInterviewer, inputParms);
        return res;
    };
}
const companyInterviewerService = new CompanyInterviewerService();
export default companyInterviewerService;