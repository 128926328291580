import React, { useState, useEffect } from "react";
import { useAppSelector } from "../../../context-store";
import vendorSubUserService from "../../../services/company/vendor-sub-user-service";
import VendorSubUserSearchInputModel from "../../../models/company/vendorUser/request/VendorSubUserSearchInputModel";
import { SortDirection } from "../../../models/enums/SortDirectionEnum";
import PagedListModel from "../../../models/common/PagedListModel";
import modalUtils from "../../../utils/modal-utils";
import alertUtils from "../../../utils/toaster-utils";
import VendorSubUserPopUp from "./VendorSubUserCreateComponent";
import { GeneralPoupConfig, ModalSize } from "../../../models/modal-types";
import VendorSubUserRequestModel from "../../../models/company/vendorUser/request/VendorSubUserRequestModel";
import VendorSubUserGridComponent from "./VendorSubUserGridComponent";
import { GuidEnum } from "../../../models/enums/GuidEnum";
import loaderUtils from "../../../utils/loader-utils";
import { PagingEnum } from "../../../models/enums/IAIEnum";
import masterDataService from "../../../services/master-data-service";
import { PageModeEnum } from "../../../models/enums/PageModeEnum";
import VendorSubUserRequirementsComponent from "./VendorSubUserRequirementsComponent";
import JobPostingVendorRequestModel from "../../../models/company/jobPosting/request/JobPostingVendorRequestModel";

const VendorSubUserComponent = () => {
    let loggedInUser = useAppSelector((state) => state.authState.loggedInUser);
    const [vendorSubUserPagedList, setVendorSubUserPagedList] = useState<PagedListModel<VendorSubUserRequestModel>>();
    const [vendorSubUserList, setVendorSubUserList] = useState<VendorSubUserRequestModel[]>([]);
    const [refreshVendorSubUser, setRefreshVendorSubUser] = useState<number>(2);
    const [vendorsubUserNameFilter, setVendorSubUserNameFilter] = useState<string>("");
    const [page, setPage] = useState<number>(PagingEnum.page);
    const [pageSize, setPageSize] = useState<number>(PagingEnum.pageSize);
    const [sortColumn, setSortColumn] = useState<string>("VendorName");
    const [sortDirection, setSortDirection] = useState<number>(PagingEnum.sortDirection);
    const [pageSizesList, setPageSizesList] = useState<number[]>([]);

    useEffect(() => {
        loadPageSizes();
        getVendorSubUsers(getSearchFilters(vendorsubUserNameFilter, page, pageSize, sortColumn, sortDirection));
    }, []);

    const loadPageSizes = () => {
        masterDataService.loadPageSizesForPaging().then((res) => {
            setPageSizesList(res);
        }).catch((error) => {
            alertUtils.showError(error);
        });
    };

    const getSearchFilters = (vendorsubUserNameFilter: string, page: number, pageSize: number, sortColumn: string, sortDirection: number) => {
        var inputData: VendorSubUserSearchInputModel = {
            vendorId: "",
            vendorSubUserName: vendorsubUserNameFilter,
            page: page,
            pageSize: pageSize,
            sort: sortColumn,
            sortDir: sortDirection
        };
        return inputData;
    };

    const getVendorSubUsers = (inputData: VendorSubUserSearchInputModel) => {
        if (loggedInUser?.profileId) {
            setRefreshVendorSubUser(2);
            inputData.vendorId = loggedInUser?.profileId;
            modalUtils.showLoader(loaderUtils.getLoadDataText());
            vendorSubUserService.getVendorSubUsers(inputData).then((res) => {
                modalUtils.closeModal();
                setVendorSubUserList([]);
                setVendorSubUserPagedList(res);
                setVendorSubUserList(res.items);
                setRefreshVendorSubUser(refreshVendorSubUser * 5);
            }).catch((error) => {
                modalUtils.closeModal();
                alertUtils.showError(error);
            });
        }
    };

    const openVendorSubUserSaveModel = (vendorSubUserRequestModel: VendorSubUserRequestModel, actionType: string) => {
        const config: GeneralPoupConfig = {
            title: "Add Vendor User",
            content: (
                <VendorSubUserPopUp
                    objVendorSubUser={vendorSubUserRequestModel}
                    actionType={actionType}
                    onCloseVendorSubUser={onCloseVendorSubUserHandler}
                    onVendorSubUserSave={onVendorSubUserSaveHandler}
                />
            ),
            size: ModalSize.LG,
            className: "model-min-height-500"
        };
        modalUtils.showPopup(config);
    };

    const onCloseVendorSubUserHandler = () => {
        modalUtils.closeModal();
    };

    const onVendorSubUserSaveHandler = (objVendor: VendorSubUserRequestModel) => {
        setRefreshVendorSubUser(2);
        objVendor.vendorId = loggedInUser ? loggedInUser.profileId : GuidEnum.Empty;
        modalUtils.showLoader(loaderUtils.getSaveDataText());
        vendorSubUserService.saveVendorSubUser(objVendor).then((res) => {
            modalUtils.closeModal();
            if (res.data === false) {
                alertUtils.showError(res.errorMessages.toString());
            } else {
                alertUtils.showSuccess(res.informationMessages.toString());
                getVendorSubUsers(getSearchFilters(vendorsubUserNameFilter, page, pageSize, sortColumn, sortDirection));

            }
        }).catch((error) => {
            modalUtils.closeModal();
            alertUtils.showError(error);
        });
    };

    const onVendorSubUserActionHandler = (obj: VendorSubUserRequestModel, actionType: string) => {
        openVendorSubUserSaveModel(obj, actionType);
    };

    const onPageChangeActionHandler = (newPage: number) => {
        setPage(newPage);
        getVendorSubUsers(getSearchFilters(vendorsubUserNameFilter, newPage, pageSize, sortColumn, sortDirection));
    };

    const onPageSizeChangeActionHandler = (newPageSize: number) => {
        setPageSize(newPageSize);
        setPage(PagingEnum.page);
        getVendorSubUsers(getSearchFilters(vendorsubUserNameFilter, PagingEnum.page, newPageSize, sortColumn, sortDirection));
    };

    const onSortActionHandler = (newSortColumn: string, sortDir: string) => {
        setSortColumn(newSortColumn);
        setSortDirection(sortDir === "asc" ? SortDirection.Asc : SortDirection.Desc);
        getVendorSubUsers(getSearchFilters(vendorsubUserNameFilter, page, pageSize, newSortColumn, sortDir === "asc" ? SortDirection.Asc : SortDirection.Desc));
    };

    const onFilterActionHandler = (filterColumn: string, filterValue: string) => {
        setVendorSubUserNameFilter(filterValue);
        setPage(0);
        getVendorSubUsers(getSearchFilters(filterValue, 0, pageSize, sortColumn, sortDirection));
    };

    const addVendor = () => {
        if (loggedInUser !== null) {
            let vendorSubUserRequestModel: VendorSubUserRequestModel = {
                vendorSubUserId: GuidEnum.Empty,
                vendorId: "",
                userId: GuidEnum.Empty,
                vendorName: "",
                vendorEmailId: "",
                vendorUserName: "",
                vendorPhoneNumber: null
            }
            onVendorSubUserActionHandler(vendorSubUserRequestModel, PageModeEnum.Create);
        }
    };

    const onJobPostingVendorActionHandler = (vendorSubUserId: string) => {
        if (loggedInUser !== null) {
            modalUtils.showLoader(loaderUtils.getLoadDataText());
            vendorSubUserService.getRequirementsForVendor(vendorSubUserId, loggedInUser.profileId).then((res) => {
                modalUtils.closeModal();
                const config: GeneralPoupConfig = {
                    title: "Map Requirements",
                    content: (
                        <VendorSubUserRequirementsComponent
                            requirementVendors={res.data.vendorRequirements}
                            vendorForRequiremrntList={res.data.requirementsListForVendor}
                            vendorSubUserId={vendorSubUserId}
                            onVendorSave={onVendorSaveHandler}
                            onCloseVendor={onCloseVendorHandler}
                        />
                    ),
                    size: ModalSize.XL,
                    className: "model-min-height-500"
                };
                modalUtils.showPopup(config);
            }).catch((error) => {
                modalUtils.closeModal();
                alertUtils.showError(error);
            });
        }
    };

    const onVendorSaveHandler = (objVendor: JobPostingVendorRequestModel) => {
        modalUtils.closeModal();
        modalUtils.showLoader(loaderUtils.getSaveDataText());
        vendorSubUserService.saveJobPostingVendorSubUser(objVendor).then((res) => {
            modalUtils.closeModal();
            if (res.data === false) {
                alertUtils.showError(res.errorMessages.toString());
            } else {
                alertUtils.showSuccess(res.informationMessages.toString());
                onJobPostingVendorActionHandler(objVendor.vendorId);
            }
        }).catch((error) => {
            modalUtils.closeModal();
            alertUtils.showError(error);
        });
    };

    const onCloseVendorHandler = () => {
        modalUtils.closeModal();
    };

    return (
        <div className="card card-white">
            <div className="card-head card-head-text-right">
                <a className="btn-grid-add-action" title="Reload Data" onClick={() => getVendorSubUsers(getSearchFilters(vendorsubUserNameFilter, page, pageSize, sortColumn, sortDirection))}>
                    <i className="fa fa-refresh"></i>
                </a>
                <a className="btn-grid-add-action" onClick={addVendor}> + Add Vendor Recruiter</a>
            </div>
            <div className="card-body">
                {refreshVendorSubUser > 2 &&
                    <VendorSubUserGridComponent
                        key={refreshVendorSubUser}
                        vendorSubUserList={vendorSubUserList}
                        totalSize={vendorSubUserPagedList?.totalCount ? vendorSubUserPagedList?.totalCount : vendorSubUserList.length}
                        currentPage={page + 1}
                        pagesize={pageSize}
                        sortColumn={sortColumn}
                        sortDirection={sortDirection === SortDirection.Asc ? "asc" : "desc"}
                        pageSizesList={pageSizesList}
                        vendorsubUserNameFilterValue={vendorsubUserNameFilter}
                        onVendorSubUserAction={onVendorSubUserActionHandler}
                        onJobPostingVendorAction={onJobPostingVendorActionHandler}
                        onPageChangeAction={onPageChangeActionHandler}
                        onPageSizeChangeAction={onPageSizeChangeActionHandler}
                        onSortAction={onSortActionHandler}
                        onFilterAction={onFilterActionHandler} />
                }
            </div>
        </div>
    );
};
export default VendorSubUserComponent;